body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  width: 100%;
}

@media (min-width: 600px) {
  .MuiDrawer-paper {
    width: 64px !important;
  }
  .css-cvumne-MuiDrawer-docked .MuiDrawer-paper, .css-1fqw99a .MuiDrawer-paper {
    width: 240px !important;
  }
}
